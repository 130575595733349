<template>
  <div class="video-page">
    <div class="video-wrapper" ref="wrapper">
      <video
        class="video video-js"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        playsinline
        webkit-playsinline
        ref="videoPlayer"
      ></video>
    </div>

    <!-- 挂号 -->
    <div class="gh-wrapper" v-if="register.length" @click="showRegister = true">
      <img src="../../assets/img/gh.png" alt="" />
      <div>去挂号</div>
    </div>
    <!-- 章节 -->
    <div class="chapter-wrapper">
      <div
        :class="{ 'chapter-item': true, active: chapterActiveIndex === i }"
        v-for="(el, i) in chapter"
        :key="i"
        @click="jumpToTime(el.duration)"
      >
        <!-- <img class="status" src="" alt="" /> -->
        <div class="chapter-item-item">
          <div class="status">
            <van-icon name="play" v-if="chapterActiveIndex !== i" />
            <svg
              v-else
              class="icon"
              style="
                width: 1em;
                height: 1em;
                vertical-align: middle;
                fill: currentColor;
                overflow: hidden;
              "
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="834"
            >
              <path
                d="M192 384m32 0l96 0q32 0 32 32l0 448q0 32-32 32l-96 0q-32 0-32-32l0-448q0-32 32-32ZM448 192m32 0l96 0q32 0 32 32l0 640q0 32-32 32l-96 0q-32 0-32-32l0-640q0-32 32-32ZM704 512m32 0l96 0q32 0 32 32l0 320q0 32-32 32l-96 0q-32 0-32-32l0-320q0-32 32-32Z"
                p-id="835"
              ></path>
            </svg>
          </div>
          <div class="info">
            <span class="time">{{ format_duration(el.duration) }}</span>
            <span class="desc">{{ el.showName }}</span>
            <van-icon name="arrow" @click.stop="showChapter = true" />
          </div>
        </div>
      </div>
    </div>
    <!-- 全屏 -->
    <div class="qp" @click="requestFullscreen">
      <img src="../../assets/img/qp.png" alt="" />
      <span>全屏查看</span>
    </div>
    <!-- 视频简介 -->
    <van-popup
      class="chapter-popup"
      get-container="body"
      v-model="showChapter"
      position="bottom"
      round
      closeable
      lock-scroll
    >
      <div class="chapter-popup-body">
        <div class="head">视频简介</div>
        <div class="desc">{{ detail.describe }}</div>
        <div class="list-wrapper">
          <div
            :class="{ item: true, active: chapterActiveIndex === i }"
            v-for="(el, i) in chapter"
            :key="i"
            @click="jumpToTime(el.duration)"
          >
            <div>
              <div class="status">
                <van-icon name="play" v-if="chapterActiveIndex !== i" />
                <svg
                  v-else
                  class="icon"
                  style="
                    width: 1em;
                    height: 1em;
                    vertical-align: middle;
                    fill: currentColor;
                    overflow: hidden;
                  "
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="834"
                >
                  <path
                    d="M192 384m32 0l96 0q32 0 32 32l0 448q0 32-32 32l-96 0q-32 0-32-32l0-448q0-32 32-32ZM448 192m32 0l96 0q32 0 32 32l0 640q0 32-32 32l-96 0q-32 0-32-32l0-640q0-32 32-32ZM704 512m32 0l96 0q32 0 32 32l0 320q0 32-32 32l-96 0q-32 0-32-32l0-320q0-32 32-32Z"
                    p-id="835"
                  ></path>
                </svg>
              </div>
              <div class="info">
                <span class="time">{{ format_duration(el.duration) }}</span>
                <span class="name">{{ el.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      class="register-popup"
      get-container="body"
      v-model="showRegister"
      position="bottom"
      round
      closeable
      lock-scroll
    >
      <div class="register-popup-body">
        <div class="head">选择挂号类型</div>
        <div class="list-wrapper">
          <div
            class="item_"
            v-for="(el, i) in register"
            :key="i"
            @click="onRegister(el)"
          >
            <div class="h-d-i-h" v-if="el.hospital_name">
              {{ el.hospital_name }}
            </div>
            <div
              :class="{ item__: true, active: chapterActiveIndex === i }"
              :style="{
                'border-radius': !el.hospital_name ? '8px' : '0px 8px 8px 8px',
              }"
            >
              <div class="item">
                <template v-if="!el.img">
                  <img
                    v-if="el.mode === '1'"
                    src="../../assets/img/ks.png"
                    alt=""
                  />
                  <img
                    v-if="el.mode === '2'"
                    src="../../assets/img/ys.png"
                    alt=""
                  />
                </template>
                <img v-else :src="el.img" alt="" />

                <div class="center" v-if="el.mode === '1'">
                  <div class="name">
                    {{ el.name }}
                  </div>
                  <div class="desc">
                    {{ el.desc }}
                  </div>
                </div>
                <div class="center" v-if="el.mode === '2'">
                  <div class="name">
                    {{ el.name }}
                    <span class="dct-title" v-if="el.desc">{{ el.desc }}</span>
                  </div>
                  <div class="desc" v-if="el.department_name">
                    {{ el.department_name }}
                  </div>
                </div>
                <!-- <van-icon name="arrow" /> -->
                <div :class="['type', 'type' + el.mode]">去挂号</div>
              </div>
              <div class="dct-desc" v-if="el.describe">
                {{ el.describe }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 播放错误 -->
    <div class="play-error" v-if="playError">视频加载失败</div>
  </div>
</template>

<script>
import videojs from "video.js"
import { play_count, play_times } from "./service"
import { format_duration } from "../../utils/format"
import { articles_actions } from "@/pages/article/service"
import { doctor_head_img } from "../../utils/imgUrl"

// const modeMap = {
//   1: "科室",
//   2: "医生",
// }

let timer = null

export default {
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // detail: {},
      articleMediaHost: GLOBAL_CONFIG.articleMediaHost,
      total: 0,
      list: [],
      deps: [],
      player: null,
      options: {
        autoplay: false,
        controls: true,
        poster: "",
        fluid: true,
        // controlBar: true,
        sources: [],
        playsinline: true,
        controlBar: {
          fluid: true,
          volumePanel: false,
          pictureInPictureToggle: false,
          fullscreenToggle: true,
          currentTimeDisplay: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          children: [
            { name: "playToggle" },
            { name: "currentTimeDisplay" },
            { name: "progressControl" },
            { name: "durationDisplay" },
            { name: "fullscreenToggle" },
          ],
        },
      },
      currentTime: 0,
      isPlay: false, //
      times: 2,
      play_session_code: generateUUID(),
      showChapter: false,
      showRegister: false,
      playError: false,
    }
  },
  computed: {
    chapter() {
      return (this.detail.chapter ? JSON.parse(this.detail.chapter) : []).map(
        (el) => {
          let count = 0
          for (let i = 0; i < el.name.length; i++) {
            if (el.name.charCodeAt(i) > 127 || el.name.charCodeAt(i) === 94) {
              count += 2
            } else {
              count++
            }
            if (count < 32) {
              el.showName = (el.showName || "") + el.name[i]
            } else {
              el.showName += "..."
              break
            }
          }
          return el
        }
      )
    },
    chapterActiveIndex() {
      const chapter = [...this.chapter]
      chapter.reverse()

      return (
        chapter.length -
        chapter.findIndex((el) => this.currentTime > el.duration) -
        1
      )
    },
    register() {
      const ans = []
      const register = this.detail.register
        ? JSON.parse(this.detail.register)
        : []
      // console.log(register)
      register.forEach((el) => {
        const data = el.options
        if (data.mode === "1" && Object.values(data.data || {}).length) {
          ans.push({
            name: data.data.name,
            type: "科室",
            mode: "1",
            desc: "免排队 在线预约专家门诊",
            data: data,
            hospital: data.data.hospital,
          })
        } else if (data.mode === "2" && data.list?.length) {
          data.list.forEach((el) => {
            ans.push({
              name: el.name,
              type: "医生",
              mode: "2",
              desc: el.title,
              describe: el.desc,
              data: { data: el, options: data },
              img: el.image_url ? doctor_head_img(el.image_url) : "",
              hospital: el.info?.hospital,
              department_name: el.department_name,
            })
          })
        }
      })
      return ans
    },
  },

  mounted() {
    if (this.detail.id) {
      this.options.sources.push({
        src: GLOBAL_CONFIG.articleMediaHost + "/" + this.detail.video_path,
      })
      if (this.detail.cover) {
        this.options.poster =
          GLOBAL_CONFIG.articleMediaHost + "/" + this.detail.cover
      }
      this.initVideo()
    }
  },
  beforeDestroy() {
    timer && clearInterval(timer)
    if (this.player) {
      this.player.dispose()
    }
  },
  watch: {
    detail() {
      timer && clearInterval(timer)
      if (this.detail.id) {
        this.options.sources.push({
          src: GLOBAL_CONFIG.articleMediaHost + "/" + this.detail.video_path,
        })
        if (this.detail.cover) {
          this.options.poster =
            GLOBAL_CONFIG.articleMediaHost + "/" + this.detail.cover
        }
        this.initVideo()
      }
    },
    current() {
      this.$nextTick(() => {
        if (this.current) {
          this.player?.play?.()
        } else {
          this.player?.pause?.()
        }
      })
    },
  },

  methods: {
    play_times() {
      try {
        play_times({
          video_no: this.detail.video_no,
          times: this.times,
          play_session_code: this.play_session_code,
        })
      } catch (error) {
        console.log(error)
      }
    },
    play_count() {
      try {
        play_count({ video_no: this.detail.video_no, count: 1 })
      } catch (error) {
        // console.log(error)
      }
    },
    requestFullscreen() {
      this.player?.requestFullscreen?.()
    },
    format_duration(time) {
      return format_duration(time, false)
    },
    jumpToTime(time) {
      // 设置当前播放时间
      this.showChapter = false
      this.player?.currentTime(time + 0.1)
      this.player.play()
    },
    initVideo() {
      const that = this
      if (this.player) {
        this.player.dispose()
      }
      this.playError = false

      this.player = videojs(this.$refs.videoPlayer, this.options, function () {
        const clear = () => {
          timer && clearInterval(timer)
        }
        // 计算播放量
        that.play_count()

        // 播放
        that.current && this.play()

        // this.player.log("onPlayerReady", this)
        this.on("timeupdate", function () {
          that.isPlay = true
          // 获取当前播放时间
          that.currentTime = this.currentTime()
          // 显示当前播放时间
          // console.log("Current time: " + that.currentTime)
        })
        // 监听全屏
        this.on("fullscreenchange", () => {
          // if(this.isFullscreen()){
          that.$refs.wrapper.querySelector(
            ".vjs-fullscreen-control"
          ).style.display = this.isFullscreen() ? "inline-block" : "none"
          // }
          // this.controls(this.isFullscreen())
        })

        this.on("pause", () => {
          console.log("暂停播放")
          that.isPlay = false
          clear()
        })
        this.on("ended", () => {
          //播放结束
          console.log("播放结束")
          that.isPlay = false
          clear()
        })
        this.on("waiting", () => {
          that.isPlay = false
          clear()
        })
        //开始播放视频时，设置一个定时器，每100毫秒调用一次aa(),观看时长加1秒

        this.on("playing", () => {
          that.isPlay = true
          that.playError = false
          timer && clearInterval(timer)
          timer = setInterval(() => {
            that.play_times()
          }, that.times * 1000)
        })

        this.on("error", () => {
          // 监听错误
          console.log("play error")
          that.playError = true
        })

        //直接关闭页面，并向后台发送数据
        if (window.addEventListener) {
          window.addEventListener(
            "beforeunload",
            () => {
              timer && clearInterval(timer)
            },
            false
          )
        } else {
          window.attachEvent("onbeforeunload", () => {
            timer && clearInterval(timer)
          })
        }
      })
      this.player.ready(() => {
        // 跳过片头
        this.player.currentTime(
          this.chapter?.find((el) => el.type === "片头")?.duration || 0
        )
      })
    },

    onRegister(data) {
      if (data.mode === "1") {
        this.onRouterRoom(data.data)
      } else if (data.mode === "2") {
        this.to_url(data.data.data, data.data.options)
      }
    },
    onRouterRoom(options) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (options.behavior && options.behavior !== "") {
        //埋点上报
        const action_id = options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 5, // 科室挂号
          department_code: options.data.code,
          department_name: options.data.name,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${
          GLOBAL_CONFIG.wenfuyi_config?.basisHost
        }/Show/yuyue/departmentpb?id=${
          this.data.options.data.id || this.data.options.data.code
        }&name=${encodeURIComponent(this.data.options.data.name)}&type=${
          this.data.options.data.category || 1
        }&trans_id=${msg_id}&code=${this.data.options.data.code}&fcode=${
          this.data.options.data.parent_code
        }`
        return
      }
      // 长海
      if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(options.data.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
        return
      }
      // 温州市中心
      if (GLOBAL_CONFIG.source === 7) {
        const data = options.data
        const redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.wzszx_config?.basisHost
          }/patient/registration/#/appointment-choise-doctor?branchCode=${
            data.hospital_id
          }&branchName=${encodeURIComponent(data.hospital_name)}&deptId=${
            data.code
          }&deptName=${encodeURIComponent(data.name)}`
        )
        location.href = `${GLOBAL_CONFIG.wzszx_config?.basisHost}/gateway/patient/connect/wechat?redirect_uri=${redirect_uri}&hospitalCode=2D8E7304D06441708AC60731D3BCCDEE`
        return
      }

      this.$router.push({
        path: "/select-doctor",
        query: {
          hospital_id: options.data.hospital_id,
          department_code: options.data.code,
          department_name: options.data.name,
        },
      })
    },
    to_url(item, options) {
      // 预览不可点
      if (this.$route.query.source === "yunying") return
      let msg_id = this.$route.query.msg_id || 1
      if (options.behavior && options.behavior !== "") {
        //埋点上报
        const action_id = options.behavior
        articles_actions({
          msg_id: msg_id,
          action_id: action_id,
          article_id: this.$route.query.id,
          component_type: 5, // 科室挂号
          doctor_code: item.code,
          doctor_name: item.name,
          department_code: item.department_code,
        })
      }
      // 温附一
      if (GLOBAL_CONFIG.source === 3) {
        location.href = `${GLOBAL_CONFIG.wenfuyi_config?.basisHost}/Show/yuyue/doctorpb2/?yhid=${item?.code}&brbh=${this.$route.query.patient_id}&lx=0&trans_id=${msg_id}`
      }
      //厦大中山
      else if (GLOBAL_CONFIG.source === 5) {
        location.href = `${GLOBAL_CONFIG.xiadazhongshang_config?.basisHost}/routeinteface/enterDoctorTime.htm?doctorId=${item.code}`
      }
      //长海
      else if ([6, 8].includes(GLOBAL_CONFIG.source)) {
        let redirect = encodeURIComponent(item.url)
        this.$router.push({
          path: "/guide-changhai",
          query: { redirect, msg_id },
        })
      }
      // 温州市中心
      else if (GLOBAL_CONFIG.source === 7) {
        let redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.wzszx_config?.basisHost
          }/patient/registration/#/doctor-info?doctorId=${
            item.code
          }&doctorName=${encodeURIComponent(
            item.name
          )}&deptId=&deptName=&branchCode=100158001&branchName=温州市中心医院`
        )
        location.href = `${GLOBAL_CONFIG.wzszx_config?.basisHost}/gateway/patient/connect/wechat?redirect_uri=${redirect_uri}&hospitalCode=2D8E7304D06441708AC60731D3BCCDEE`
      } else if (item.s_url || item.url) {
        location.href = item.s_url || item.url
      } else {
        this.$router.push({
          path: "/doctor-page",
          query: {
            hospital_id: item.hospital_id,
            department_code: item.department_code,
            department_name: item.department_name,
            doctor_id: item.code,
          },
        })
      }
    },
  },
}

function generateUUID() {
  var d = new Date().getTime()
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now() //use high-precision timer if available
  }
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}
</script>



<style lang="less" scoped>
.dct-desc {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 13px;
  color: #939aa6;
  line-height: 18px;
  margin-left: 60px;
  margin-top: 4px;
}
.video-page {
  height: 100%;
  position: relative;
  .play-error {
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 4px 8px;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .qp {
    position: absolute;
    width: 93px;
    height: 28px;
    background: #292929;
    border-radius: 112px 112px 112px 112px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 28vh;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      margin-right: 8px;
    }
  }
  .chapter-wrapper {
    overflow-x: auto;
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 12px;
    left: 0;
    padding: 0 16px;
    bottom: 53px;
    scrollbar-width: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .chapter-item {
      width: 200px;
      height: 54px;
      padding: 12px;
      flex: 0 0 200px;
      background: #292929;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #b3bac4;
      &.active {
        background: rgba(189, 212, 255, 0.33);
        color: #fff;
        .status {
          color: #377dff;
        }
      }
      .icon {
        margin-top: -2px;
      }
      .time {
        margin-right: 8px;
      }
      .status {
        margin-right: 12px;
        padding-top: 3px;
      }
      .info {
        line-height: 20px;
      }
    }
    .chapter-item-item {
      display: flex;
      align-items: center;
    }
    .chapter-item + .chapter-item {
      margin-left: 8px;
    }
    .status {
      align-self: flex-start;
    }
  }
  .gh-wrapper {
    position: absolute;
    width: 53px;
    height: 68px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #fff;
    bottom: 140px;
    right: 16px;
    // filter: blur(23px);
    backdrop-filter: blur(23px);
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 41px;
      margin-bottom: 2px;
    }
  }
  .video-wrapper {
    height: 100%;
    background: #000;
    display: flex;
    align-items: center;
    position: relative;
  }
  .video {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    // overflow: hidden;
  }
  video {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
  .head {
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    color: #b3bac4;
    z-index: 10;
    padding: 0 16px;
    top: 0;
    left: 0;
    .arrow {
      left: 24px;
      position: absolute;
    }
    .video-page-menu-wrapper {
      // flex: 1;
      text-align: center;
    }
  }
}
</style>

<style lang="less">
.video-page {
  .video-js .vjs-current-time,
  .vjs-no-flex .vjs-current-time,
  .video-js .vjs-duration,
  .vjs-no-flex .vjs-duration {
    display: flex;
  }
  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
  }
  .video-js .vjs-control-bar {
    background: transparent;
  }
  .vjs-current-time-display,
  .vjs-duration-display {
    font-size: 14px;
    font-weight: 500;
  }
  .video-js .vjs-play-progress:before {
    font-size: 12px;
    // top: -5px;
  }
  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
  }
  .vjs-fullscreen-control {
    display: none;
  }
  .vjs-error-display {
    display: none;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.4em;
    line-height: 1.2;
  }
}
.video-page-menu {
  display: inline-block;
  .van-dropdown-menu__bar {
    background-color: #292929;
    border-radius: 32px;
    color: #fff;
    min-width: 100px;
    height: 32px;
    padding: 0 16px;
    .van-dropdown-menu__title {
      color: #fff;
    }
  }
}
.chapter-popup-body {
  padding: 20px;
  .head {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 14px;
    color: #b3bac4;
    margin-top: 24px;
    margin-bottom: 12px;
    line-height: 21px;
  }
  .list-wrapper {
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .item {
    height: 64px;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #b3bac4;
    & > div {
      display: flex;
      align-items: center;
    }
    &.active {
      background: #f3f8ff;
      color: #fff;
      .status {
        color: #377dff;
      }
    }
    .icon {
      margin-top: -2px;
    }
    .time {
      margin-right: 8px;
      color: #333;
      white-space: nowrap;
      line-height: 16px;
    }
    .status {
      margin-right: 12px;
      // padding-top: 3px;
      font-size: 16px;
    }
    .name {
      color: #55637b;
      line-height: 16px;
    }
    .info {
      line-height: 20px;
      display: flex;
    }
  }

  .status {
    align-self: flex-start;
  }
}
.chapter-popup .van-popup__close-icon,
.register-popup .van-popup__close-icon {
  color: #000;
}

.register-popup-body {
  padding: 20px 0 49px 0;

  .dct-title {
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    border-left: 1px solid #939aa6;
    padding-left: 4px;
    margin-left: 8px;
  }
  .head {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-align: center;
    margin-bottom: 14px;
  }
  .list-wrapper {
    max-height: calc(66.6vh - 88px);
    overflow-y: auto;
    // margin-bottom: -20px;
    // margin-top: -10px;
    scrollbar-width: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .h-d-i-h {
    height: 21px;
    // line-height: 21px;
    background: linear-gradient(90deg, #fdf3e6 0%, #fcf0dd 45%, #fcecd5 100%);
    box-shadow: 0px 0px 12px 0px rgba(176, 183, 194, 0.12);
    border-radius: 4px 16px 0px 0px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    color: #b9875f;
    // margin-left: 14px;
  }
  .item_ {
    margin: 11px 20px 11px 20px;
  }
  .item__ {
    padding: 16px 12px;
    font-size: 14px;
    color: #b3bac4;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
    border-radius: 0px 8px 8px 8px;
  }
  .item {
    // height: 70px;
    // padding: 16px;
    // border-radius: 4px;
    display: flex;
    align-items: center;
    // font-size: 14px;
    // color: #b3bac4;
    // position: relative;
    // background: #ffffff;
    // box-shadow: 0px 0px 12px 0px rgba(161, 165, 172, 0.2);
    // border-radius: 0px 8px 8px 8px;
  }
  .item_ + .item_ {
    margin-top: 20px;
  }
  .name {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
  }
  .center {
    flex: 1;
    margin-right: 16px;
  }
  img {
    width: 44px;
    margin-right: 16px;
    object-fit: cover;
    border-radius: 50%;
    height: 44px;
  }
  .desc {
    font-size: 14px;
    color: #666666;
    margin-top: 6px;
  }
  .type {
    width: 68px;
    height: 28px;
    color: #fff;
    font-weight: bold;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    /* font-size: 14px; */
    font-weight: 600;
    font-size: 12px;
  }
  .type1 {
    background: #5bc98e;
  }
  .type2 {
    background: #418cf7;
  }
  .van-icon {
    color: #bec2c9;
  }
}
</style>