<template>
  <div class="video-page">
    <van-swipe
      style="height: 100%"
      vertical
      :show-indicators="false"
      @change="swipeChange"
      :initial-swipe="swipeIndex"
      lazy-render
      :loop="videoSwitch"
    >
      <van-swipe-item v-for="(el, i) in list" :key="el.id" :data-id="el.id">
        <CustomVideo :detail="el" :current="i === swipeIndex" />
      </van-swipe-item>
    </van-swipe>
    <div class="head">
      <van-icon class="arrow" name="arrow-left" @click="$router.go(-1)" />
      <div class="video-page-menu-wrapper">
        <!-- <van-dropdown-menu class="video-page-menu">
          <van-dropdown-item
            v-model="form.type_code"
            :options="deps"
            @change="typeChange"
          />
        </van-dropdown-menu> -->
      </div>
    </div>
  </div>
</template>

<script>
import { detail_video_api, get_departments, get_list_api } from "./service"
import CustomVideo from "./video.vue"

const form = {
  page: 0,
  page_size: 3,
  type_code: "",
}

export default {
  data() {
    return {
      detail: {},
      articleMediaHost: GLOBAL_CONFIG.articleMediaHost,
      total: 0,
      list: [],
      form: { ...form },
      deps: [],
      swipeIndex: 0,
      pageIndex: 3,
      videoList: [],
      videoSwitch: window.GLOBAL_CONFIG.videoSwitch,
    }
  },
  computed: {
    // videoList() {
    // const ans = []
    // if (this.detail) {
    //   //
    //   ans[this.swipeIndex] = this.detail
    // }
    // if (this.swipeIndex === 0) {
    //   ans.push(...this.list.slice(this.pageIndex, 2))
    // }
    // if (this.swipeIndex === 1) {
    //   // ans.push(...this.list.slice(this.pageIndex,2))
    //   ans[0] = this.list[this.pageIndex - 1]
    //   ans[0] = this.list[this.pageIndex + 1]
    // }
    // if (this.swipeIndex === 2) {
    //   ans.unshift(...this.list.slice(this.pageIndex, 2))
    // }
    // if (this.swipeIndex === 2) {
    //   ans.unshift(...this.list.slice(this.pageIndex, 2), this.detail)
    // }
    //   if (this.swipeIndex === 2) {
    //     ans.push(...this.list.slice(this.pageIndex, 2))
    //   }
    //   return ans.filter((el) => el)
    // },
  },
  components: {
    CustomVideo,
  },
  created() {
    this.get_detail()
    this.get_departments()
  },

  methods: {
    // typeChange() {
    //   this.form.page = 0
    //   // this.total = 0
    //   this.list = [this.list[this.swipeIndex]]
    //   this.get_list(1)
    // },
    async get_list(type) {
      if (!window.GLOBAL_CONFIG.videoSwitch) return
      const { data } = await get_list_api(this.form)
      this.total = data.total_elements
      // if (type === 1) {
      this.list.push(...data.data.filter((el) => el.id !== this.detail?.id))
      this.videoList = this.list.slice(0, 3)
      // } else {
      //   this.list.push(...data.data)
      // }
      console.log(type)

      // 重置page
      // if ((data.page_num + 1) * data.page_size >= data.total_elements) {
      //   this.form.page = 0
      // }
    },
    swipeChange(i) {
      this.swipeIndex = i
      // console.log("this.swipeIndex", i, this.list.length - 1)
      if (i >= this.list.length - 1) {
        this.form.page = this.form.page + 1
        if (this.form.page * this.form.page_size < this.total) {
          this.get_list()
        }
      }

      this.detail = [this.list[i]]

      // console.log(
      //   "swipeChange",
      //   i,
      //   this.videoList.length - 1,
      //   this.list?.map((el) => el.id),
      //   this.videoList?.map((el) => el.id)
      // )
      // if (i === this.videoList.length - 1) {
      //   const videoList = []
      //   // videoList[i] = this.videoList[i]
      //   videoList.push(
      //     this.videoList[i],
      //     ...this.list.slice(this.pageIndex, this.pageIndex + 2)
      //   )
      //   this.videoList = videoList.filter((el) => el)
      //   console.log(this.videoList?.map((el) => el.id))
      //   this.pageIndex += 3

      //   if (this.pageIndex >= this.list.length - 1) {
      //     console.log(this.form.page)
      //     this.pageIndex = 0
      //     if (this.form.page) {
      //       this.form.page++
      //     }
      //     this.get_list()
      //   }
      // }

      // this.pageIndex++
      // this.detail = this.list[this.pageIndex]
      // if (this.pageIndex >= this.list.length - 1) {
      //   if (this.form.page) {
      //     this.form.page++
      //   }
      //   this.get_list()
      // }
    },
    async get_departments() {
      const { data } = await get_departments()

      this.deps = [
        { text: "全部", value: "" },
        ...data.departments?.map((el) => ({
          text: el.name,
          value: el.code,
        })),
      ]
    },
    async get_detail() {
      const video_no = this.$route.query.video_no
      if (video_no) {
        const { data } = await detail_video_api({ video_no })
        this.detail = data
        this.list.push(data)
        this.videoList = this.list.slice(0, 3)
        this.get_list(1)
      }
    },
  },
}
</script>



<style lang="less" scoped>
.video-page {
  height: 100%;
  position: relative;
  background: #000;
  .qp {
    position: absolute;
    width: 115px;
    height: 32px;
    background: #292929;
    border-radius: 112px 112px 112px 112px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 28vh;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      margin-right: 8px;
    }
  }
  .chapter-wrapper {
    overflow-x: auto;
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 12px;
    left: 0;
    padding: 0 16px;
    bottom: 53px;
    scrollbar-width: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .chapter-item {
      width: 200px;
      height: 54px;
      padding: 12px;
      flex: 0 0 200px;
      background: #292929;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #b3bac4;
      &.active {
        background: rgba(189, 212, 255, 0.33);
        color: #fff;
        .status {
          color: #377dff;
        }
      }
      .icon {
        margin-top: -2px;
      }
      .time {
        margin-right: 8px;
      }
      .status {
        margin-right: 12px;
        padding-top: 3px;
      }
      .info {
        line-height: 20px;
      }
    }
    .chapter-item-item {
      display: flex;
      align-items: center;
    }
    .chapter-item + .chapter-item {
      margin-left: 8px;
    }
    .status {
      align-self: flex-start;
    }
  }
  .gh-wrapper {
    position: absolute;
    width: 59px;
    height: 77px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #fff;
    bottom: 140px;
    right: 16px;
    // filter: blur(23px);
    backdrop-filter: blur(23px);
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 41px;
      margin-bottom: 2px;
    }
  }
  .video-wrapper {
    height: 100%;
    background: #000;
    display: flex;
    align-items: center;
    position: relative;
  }
  .video {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    // overflow: hidden;
  }
  video {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
  .head {
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    color: #b3bac4;
    z-index: 10;
    padding: 0 16px;
    top: 20px;
    left: 0;
    .arrow {
      left: 12px;
      position: absolute;
      font-size: 24px;
    }
    .video-page-menu-wrapper {
      // flex: 1;
      text-align: center;
    }
  }
}
</style>

<style lang="less">
.video-page-menu {
  display: inline-block;
  .van-dropdown-menu__bar {
    background-color: #292929;
    border-radius: 32px;
    color: #fff;
    min-width: 100px;
    height: 32px;
    padding: 0 16px;
    .van-dropdown-menu__title {
      color: #fff;
    }
  }
}
</style>